<!-- 审核 -->
<template>
  <div class="content">
    <div class="nav" style="cursor: pointer;">
      <div @click="goback">&lt;</div>
      <!-- <div style="color: #c8c8c8" @click="goback">考试管理/层级一：答应</div> -->
      <div @click="goback">审核</div>
    </div>
    <!-- <div style="display: flex; align-items: center">
      <div class="line"></div>
      <div style="font-size: 20px; font-weight: bold">学员信息</div>
    </div> -->
    <div class="information">
      <div>等级：{{ obj.levelName }}</div>
      <div>地区:{{ obj.localtion }}</div>
      <div>上传时间：{{ objdetails.reviewUser.uploadDate }}</div>
    </div>
    <div >
      <div style="display: flex; align-items: center">
        <div class="line"></div>
        <div style="font-size: 20px; font-weight: bold">考题</div>
        <!-- <div style="color: #999; margin-left: 20px">{{objdetails.reviewUser.reviewInfoName}}</div> -->
      </div>
      <div style="margin:0 68px;display:flex;margin:20px 68px">
        <div>题目：</div>
        <div style="color: #999; margin-left: 20px">{{objdetails.reviewUser.reviewInfoName}}</div>
      </div>
      <div style="margin:0 68px;display:flex;margin:20px 68px">
        <div>详情：</div>
        <div style="color: #999; margin-left: 20px">{{objdetails.reviewUser.des}}</div>
      </div>
       <!-- <div style="margin:20px 0;display: flex; align-items: center">
         <div style="font-size: 20px; ">详情</div>
         <div style="color: #999; margin-left: 20px">{{objdetails.reviewUser.des}}</div>
      </div> -->
    </div>
    <div style="display: flex; align-items: center">
      <div class="line"></div>
      <div style="font-size: 20px; font-weight: bold">审核内容</div>
      <div style="color: #409eff; margin-left: 20px">历史审核</div>
    </div>
    <!-- <div
      style="
        display: flex;
        align-items: center;
        margin: 30px 0 30px 67px;
        font-size: 20px;
      "
    >
      <div>试卷答题情况：</div>
      <div style="color: #409eff; margin: 0 15px">80分</div>
      <el-button type="primary" class="btn" @click="details"
        >查看详情</el-button
      >
    </div> -->
    <div class="content2">
      <div>
        <div style="display: flex; align-items: center; margin: 15px 0">
          <div style="font-size: 20px">图片资料:</div>
          <div style="font-size: 14px; color: #c8c8c8; margin-left: 20px">
            (点击查看大图)
          </div>
        </div>
        <div
          style="
            width: 515px;
            height: 307px;
            border-radius: 14px;
            margin: 15px 0;
            position: relative;
          "
        >
          <el-carousel trigger="click" :autoplay='false' @change='selimg'>
            <el-carousel-item v-for="(item,index) in objdetails.picList" :key="index" style="border-radius:14px">
              <img :src="item.resourceUri" alt="" class='image' @click='lookimg'>
            </el-carousel-item>
          </el-carousel>
          <!-- <img
            src="../../images/head.png"
            style="width: 100%; height: 100%; border-radius: 14px"
            alt=""
          />
          <div style="display: flex;position:absolute;bottom:16px;right:20px;background:rgba(0,0,0,0.35);border-radius:30px">
            <div class="buttomlr">&lt;</div>
            <div style="line-height: 34px; margin-left: 25px;color:#fff">1</div>
            <div style="color: rgba(0,0,0,0.35); line-height: 34px; margin-right: 25px">
              /5
            </div>
            <div class="buttomlr">&gt;</div>
          </div> -->
        </div>
        <!-- <div style="font-size: 20px">图片介绍:xxx</div> -->
      </div>
      <!--视频资料-->
      <div>
        <div style="display: flex; align-items: center; margin: 15px 0">
          <div style="font-size: 20px">视频资料:</div>
        </div>
        <div
          style="
            width: 515px;
            height: 307px;
            border-radius: 14px;
            margin: 15px 0;
            position: relative;
          "
        >
         <el-carousel trigger="click" :autoplay='false'>
            <el-carousel-item v-for="(item,index) in objdetails.videoList" :key="index" style="border-radius:14px">
              <video :src="item.resourceUri" class='video' controls ></video>
            </el-carousel-item>
          </el-carousel>
          <!-- <img
            src="../../images/head.png"
            style="width: 100%; height: 100%; border-radius: 14px"
            alt=""
          />
          <div
            style="
              display: flex;
              position: absolute;
              bottom: 16px;
              right: 20px;
              background: rgba(0, 0, 0, 0.35);
              border-radius: 30px;
            "
          >
            <div class="buttomlr">&lt;</div>
            <div style="line-height: 34px; margin-left: 25px; color: #fff">
              1
            </div>
            <div
              style="
                color: rgba(0, 0, 0, 0.35);
                line-height: 34px;
                margin-right: 25px;
              "
            >
              /5
            </div>
            <div class="buttomlr">&gt;</div>
          </div> -->
        </div>
        <!-- <div style="font-size: 20px">视频介绍:xxx</div> -->
      </div>
    </div>
    
    <div
      style="
        display: flex;
        flex-direction: column;
        margin: 30px 57px 60px;
        padding-bottom: 60px;
      "
    >
    <div></div>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="不通过请输入原因"
        v-model="textarea"
        v-if='objdetails.reviewUser.passState==0'
      >
      </el-input>
      <div style="font-size:20px" v-if='objdetails.reviewUser.passState==-1'>审核不通过原因:{{objdetails.reviewUser.reviewerComment}}</div>
      <div style="display: flex; justify-content: center; margin-top: 30px" v-if='objdetails.reviewUser.passState==0'>
        <button class="ctb" style="background: #ff584c" @click='nopass'>不通过</button>
        <button class="ctb" style="background: #409eff; margin-left: 57px" @click='pass'>
          通过
        </button>
      </div>
      <!-- <div style="display: flex; justify-content: center; align-items: center">
        <div
          style="
            display: flex;
            width: 98px;
            height: 33px;
            color: #cacaca;
            border: 1px solid #cacaca;
            align-items: center;
            justify-content: center;
          "
        >
          <div>&lt;</div>
          <div>上一题</div>
        </div>
        <div style="display: flex; margin: 20px">
          <div>2</div>
          <div>/3</div>
        </div>
        <div
          style="
            display: flex;
            width: 98px;
            height: 33px;
            color: #cacaca;
            align-items: center;
            justify-content: center;
             border: 1px solid #cacaca;
            background: #fff;
          "
        >
          <div>下一题</div>
          <div>&gt;</div>
        </div>
      </div> -->
    </div>
    <img
      v-if="objdetails.reviewUser.passState==1"
      src="../../images/through2.png"
      alt=""
      style="width: 300px; height: 300px; position: absolute; top: 0; right: 0"
    />
    <img
     v-if="objdetails.reviewUser.passState==-1"
      src="../../images/nothrough.png"
      alt=""
      style="width: 300px; height: 300px; position: absolute; top: 0; right: 0"
    />




    <el-dialog

  :visible.sync="dialogVisible"
  width="80%"
  :before-close="handleClose">
  <!-- <span>这是一段信息</span> -->
  <img :src="imgurl" alt="" style="width:100%">
</el-dialog>
  </div>
  
</template>



<script>
import { reviewUserDetails,reviewUserupdate} from "../../apis/index";
export default {
  data() {
    return {
      imgurl:'',
      dialogVisible:false,
      index:0,
      obj: "",
      objdetails: {},
      through: true,
      answer: false,
      textarea: "",
    };
  },
  created() {
    let obj = JSON.parse(sessionStorage.getItem("auditobj"));
    this.obj = obj;
    let id = this.$route.params.id;
    this.auditdetails(id);
  },
  methods: {
    lookimg(){
      if(this.objdetails.picList[this.index].resourceUri){
         this.imgurl=this.objdetails.picList[this.index].resourceUri
         this.dialogVisible=true
      }
     
      console.log()
    },
    selimg(newind,oldind){
     this.index=newind
    },
   async pass(){//通过
      const params={
        id:this.objdetails.reviewUser.id,
        passState:1,
      }
    const {data}=await reviewUserupdate(params)
    if(data.code==200){
      this.$message.success('操作成功')
      this.$router.go(-1)
    }else{
      this.$message.error(data.msg)
    }
    },
   async nopass(){//不通过
       const params={
        id:this.objdetails.reviewUser.id,
        passState:-1,
        reviewerComment:this.textarea
      }
     const {data}=await reviewUserupdate(params)
     if(data.code==200){
      this.$message.success('操作成功')
      this.$router.go(-1)
    }else{
      this.$message.error(data.msg)
    }
    },
    async auditdetails(id) {//页面详情
      const { data } = await reviewUserDetails({ id });
      if (data.code == 200) {
        this.objdetails = data.data;
      } else {
        this.$message.error(data.msg);
      }
      console.log(data.data);
    },
    goback() {
      this.$router.go(-1);
    },
    lookanswer() {
      this.answer = !this.answer;
    },
    details() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='less' scoped>
.ctb {
  width: 200px;
  height: 54px;
  border-radius: 14px;
  border: none;
  font-size: 18px;
  line-height: 54px;
  color: #fff;
}
/deep/.el-dialog__body {
  //   padding: 30px 40px;
  padding: 30px 70px;
  max-height: 646px;
  overflow: auto;
}
.selectA {
  display: flex;
  color: #bababa;
  margin-top: 15px;
  div {
    margin: 0 20px;
    font-size: 20px;
  }
}
.selectA > div:first-child {
  margin-left: 0;
}
.line2 {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin-right: 11px;
}
.buttomlr {
  width: 34px;
  height: 34px;
  // border: 1px solid #e4e4e4;
  line-height: 34px;
  text-align: center;
  border-radius: 6px;
  color: #e4e4e4;
}
.content2 {
  display: flex;
  margin: 0 68px;
  justify-content: space-between;
}
/deep/.el-button {
  height: 34px;
}
.information {
  display: flex;
  // margin: 30px 0 60px 67px;
  margin: 15px 0 40px 67px;
  width: 60%;
  justify-content: space-between;
  color: #c8c8c8;
}
.line {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin: 0 11px 0 67px;
}
.content {
  margin: 13px 0;
  background: #fff;
  position: relative;
  .nav {
    display: flex;
    font-size: 26px;
    font-weight: bold;
    padding: 23px 0;
    div:first-child {
      width: 57px;
      text-align: center;
      color: #c8c8c8;
    }
  }
}
.image,.video{
  background-size: 100% 100%;
  width: 515px;
  height: 307px;
  border-radius:14px;

}
</style>
